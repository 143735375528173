import * as Type from "./types";
const ConversationActiveContactReducer = (
  state = {
    currentUser: null,
    labelCompression: false,
    tab: "All Chat",
  },
  action
) => {
  switch (action.type) {
    case Type.SET_ACTIVE_CONTACT:
      state = {
        ...state,
        activeContact: action.payload,
      };
      break;
    case Type.SET_REDIRECTED_ID:
      state = {
        ...state,
        notificationId: action.payload,
      };
      break;
    case Type.SET_HANDLE_REFRESH:
      state = {
        ...state,
        refreshContactList: action.payload,
      };
      break;
    case Type.SET_CURRENT_USER:
      state = {
        ...state,
        currentUser: action.payload,
      };
      break;
    case Type.SET_ACTIVE_TAB:
      state = {
        ...state,
        activeTab: action.payload,
      };
      break;
    case Type.SET_TAB:
      state = {
        ...state,
        tab: action.payload,
      };
      break;
    case Type.SET_SENDER_INFO:
      state = {
        ...state,
        senderInfo: action.payload,
      };
      break;
    case Type.SET_INTERNAL_INPUT:
      state = {
        ...state,
        internalInput: action.payload,
      };
      break;
    case Type.SET_ATTACHMENT_INPUT:
      state = {
        ...state,
        acttachmentInput: action.payload,
      };
      break;
    case Type.SET_QUICK_REPLY:
      state = {
        ...state,
        selectedQuickReply: action.payload,
      };
      break;
    case Type.SET_SELECTED_ATTACHMENT:
      state = {
        ...state,
        selectedAttachment: action.payload,
      };
      break;
    case Type.UPDATE_QUICK_REPLY_FORMAT:
      return {
        ...state,
        selectedQuickReply: {
          ...state.selectedQuickReply,
          format: action.payload,
        },
      };
      break;
    default:
      break;
  }
  return state;
};

export default ConversationActiveContactReducer;
