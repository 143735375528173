import {
  SetConversationArr,
  SetLoading,
  SetConversations,
  SetPage,
} from "./action";
import ConversationListReducer from "./reducer";
import { getConversationsByWorkspaceId } from "../../services/workspace/conversation";

// Action to fetch conversations
const getConversations = () => {
  return async (dispatch) => {
    dispatch(SetLoading(true));
    try {
      const res = await getConversationsByWorkspaceId();
      if (res.status === 200) {
        dispatch(SetConversationArr(res.data));
      } else {
        console.log(`Unexpected status code: ${res.status}`);
      }
    } catch (error) {
      console.log("Error fetching conversations:", error);
    } finally {
      dispatch(SetLoading(false));
    }
  };
};

export {
  ConversationListReducer,
  SetConversationArr,
  getConversations,
  SetConversations,
  SetPage,
};
