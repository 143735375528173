import * as Type from "./types";

const initialState = {
  labelCompression: false,
};

const ConversationLabelReducer = (state = initialState, action) => {
  switch (action.type) {
    case Type.SET_CONVERSATION_LABEL_COMPRESSION:
      return {
        ...state,
        labelCompression: action.payload,
      };
    default:
      return state;
  }
};

export default ConversationLabelReducer;
