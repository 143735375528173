import * as Type from "./types";

const crmSettingReducer = (
  state = { settingActingTab: "automation-rules" },
  action
) => {
  switch (action.type) {
    case Type.SET_SETTING_ACTIVE_TAB:
      state = { settingActingTab: action.payload };
      break;
    default:
      break;
  }
  return state;
};
export default crmSettingReducer;
