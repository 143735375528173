import { SET_LABELS, SET_REFETCH_LABEL } from "./types";

const initialState = {
  labels: [],
};

const labelsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LABELS:
      return {
        ...state,
        labels: action.payload,
      };

    case SET_REFETCH_LABEL:
      return {
        ...state,
        refetchLabel: action.payload,
      };
    default:
      return state;
  }
};

export default labelsReducer;
