import * as Type from "./types";

const ConversationListReducer = (
  state = {
    loading: true,
    data: [],
    conversations: [],
    page: 2,
  },
  action
) => {
  switch (action.type) {
    case Type.SET_CONVERSATION_ARR:
      state = {
        ...state,
        data: action.payload,
      };
      break;
    case Type.SET_CONVERSATIONS:
      state = {
        ...state,
        conversations: action.payload,
      };
      break;
    case Type.SET_PAGE:
      state = {
        ...state,
        page: action.payload,
      };
      break;
    case Type.SET_LOADING:
      state = {
        ...state,
        loading: action.payload,
      };
      break;
    default:
      break;
  }
  return state;
};

export default ConversationListReducer;
