import * as Type from "./types";

const initialState = {
  attachments: [],
};

const MediaReducer = (state = initialState, action) => {
  switch (action.type) {
    case Type.ADD_ATTACHMENT:
      return {
        ...state,
        attachments: action.payload,
      };
    case Type.SET_SELECTED_TEMPLATE:
      return {
        ...state,
        selectedTemplate: action.payload,
      };
    default:
      return state;
  }
};
//   switch (action.type) {
//     case Type.ADD_ATTACHMENT:
//       return {
//         ...state,
//         attachments: state.attachments.some(
//           (item) => item.productId === action.payload.productId
//         )
//           ? state.attachments.map((item) =>
//               item.productId === action.payload.productId
//                 ? {
//                     ...item,
//                     attachments: [
//                       ...item.attachments,
//                       action.payload.attachment,
//                     ],
//                   }
//                 : item
//             )
//           : [
//               ...state.attachments,
//               {
//                 productId: action.payload.productId,
//                 message: action.payload.message, // Adjust if you have a default message
//                 attachments: [action.payload.attachment],
//               },
//             ],
//       };
//     case Type.REMOVE_ATTACHMENT:
//       return {
//         ...state,
//         attachments: state.attachments
//           .map((item) =>
//             item.productId === action.payload.productId
//               ? {
//                   ...item,
//                   attachments: item.attachments.filter(
//                     (att) => att._id !== action.payload.attachmentId
//                   ),
//                 }
//               : item
//           )
//           .filter((item) => item.attachments.length > 0),
//       };
//     case Type.RESET_ATTACHMENTS:
//       return {
//         ...state,
//         attachments: [],
//       };
//     case Type.SET_SELECTED_TEMPLATE:
//       return {
//         ...state,
//         selectedTemplate: action.payload,
//       };
//     default:
//       return state;
//   }
// };

export default MediaReducer;
