import * as Type from "./types";
const initialState = {
  selectedImages: [],
};

const AttachmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case Type.ADD_SELECTED_IMAGE:
      return {
        ...state,
        selectedImages: [...state.selectedImages, action.payload],
      };
    case Type.REMOVE_SELECTED_IMAGE:
      return {
        ...state,
        selectedImages: state.selectedImages.filter(
          (img) => img._id !== action.payload
        ),
      };
    case Type.UPDATE_SELECTED_IMAGES:
      return {
        ...state,
        selectedImages: state.selectedImages.map((image) =>
          image._id === action.payload._id ? action.payload : image
        ),
      };
    case Type.UPDATE_SELECTED_IMAGES:
      return {
        ...state,
        selectedImages: action.payload,
      };
    case Type.RESET_SELECTED_IMAGES: // Handle the new action type
      return {
        ...state,
        selectedImages: [],
      };
    case Type.REPLACE_IAMGES: // Handle the new action type
      return {
        ...state,
        selectedImages: action.payload ?? [],
      };
    case Type.UPDATE_IMAGE_MESSAGE:
      return {
        ...state,
        selectedImages: [
          ...state.selectedImages.map((image) =>
            image._id === action.payload.imageId
              ? {
                  ...image,
                  message: action.payload.message,
                }
              : { ...image }
          ),
        ],
      };
    default:
      return state;
  }
};

export default AttachmentReducer;
