import * as Types from "./types";

export const addSelectedImage = (image) => ({
  type: Types.ADD_SELECTED_IMAGE,
  payload: image,
});

export const removeSelectedImage = (imageId) => ({
  type: Types.REMOVE_SELECTED_IMAGE,
  payload: imageId,
});
export const setEmptyImage = ([]) => ({
  type: Types.REMOVE_SELECTED_IMAGE,
  payload: [],
});
export const resetSelectedImages = () => ({
  type: Types.RESET_SELECTED_IMAGES,
});
export const replaceImages = (images) => ({
  type: Types.REPLACE_IAMGES,
  payload: images,
});
export const updateSelectedImages = (updatedImages) => ({
  type: Types.UPDATE_SELECTED_IMAGES,
  payload: updatedImages,
});

export const updateImageMessage = (imageId, message) => ({
  type: Types.UPDATE_IMAGE_MESSAGE,
  payload: { imageId, message },
});
