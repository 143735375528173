import * as Type from "./types";
const ConversationProfileReducer = (state = [], action) => {
  switch (action.type) {
    case Type.SET_PROFILE:
      state = {
        ...state,
        profile: action.payload,
      };
      break;

    default:
      break;
  }
  return state;
};

export default ConversationProfileReducer;
