import { ParseError, getAuthHeader } from "../../config/utils";
import instance from "../../config/axios";

const getSuperUserData = async () => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const token = await getAuthHeader();
  return instance
    .get(`/superAdmin/user?limit=${1000}`, token)
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        response = response.data;
        return {
          data: response.data,
          message: response.message,
          status: response.code,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

const updateDummyOrders = async (values) => {
  const token = await getAuthHeader();
  return instance
    .post(`/orderCsv/updateOrders`, values, token)
    .then((response) => {
      response = response.data;
      if (response.code === 200 || response.code === 201) {
        return {
          message: response.message,
          status: response.code,
          data: response.data,
        };
      } else {
        return {
          status: response.code,
          message: response.message,
        };
      }
    })
    .catch((err) => {
      return {
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

const importCSVOrders = async (values) => {
  const token = await getAuthHeader();
  return instance
    .post(`/orderCsv`, values, token)
    .then((response) => {
      response = response.data;
      if (response.code === 200 || response.code === 201) {
        return {
          message: response.message,
          status: response.code,
          data: response.data,
        };
      } else {
        return {
          status: response.code,
          message: response.message,
        };
      }
    })
    .catch((err) => {
      return {
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

const getUserWorkspaces = async (id) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const token = await getAuthHeader();
  return instance
    .get(`/superAdmin/userWorkspaces/${id}`, token)
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        response = response.data;
        return {
          data: response.data,
          status: response.code,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};
const GetWorkspaceList = async (page = 1, limit = 100000, size = true) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };

  const token = await getAuthHeader();
  return instance
    .get(
      `/superAdmin/getWorkspaceList?page=${page}&limit=${limit}&size=${size}`,
      token
    )
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        response = response.data;
        if (response.code === 200) {
          return {
            ...responseData,
            status: response.code,
            data: response.data,
          };
        } else {
          return {
            ...responseData,
            message: response.message,
          };
        }
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      console.log(err, "err");
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};
const GetWorkspacesDetail = async (page = 1, limit = 100000, size = true) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };

  const token = await getAuthHeader();
  return instance
    .get(
      `/superAdmin/workspacesDetails?page=${page}&limit=${limit}&size=${size}`,
      token
    )
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        response = response.data;
        if (response.code === 200) {
          return {
            ...responseData,
            status: response.code,
            data: response.data,
            message: response.message,
            count: response.count,
          };
        } else {
          return {
            ...responseData,
            message: response.message,
          };
        }
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      console.log(err, "err");
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

const GetWebsites = async (page = 1, limit = 100000) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };

  const token = await getAuthHeader();
  return instance
    .get(`/superAdmin/websites?page=${page}&limit=${limit}`, token)
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        response = response.data;
        if (response.code === 200) {
          return {
            ...responseData,
            status: response.code,
            data: response.data,
            message: response.message,
            count: response.count,
          };
        } else {
          return {
            ...responseData,
            message: response.message,
          };
        }
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      console.log(err, "err");
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

const GetWorkspacesDetailByQuery = async (
  page = 1,
  limit = 100000,
  query = ""
) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };

  const token = await getAuthHeader();
  return instance
    .get(
      `/superAdmin/workspacesDetailsByQuery?page=${page}&limit=${limit}&query=${query}`,
      token
    )
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        response = response.data;
        if (response.code === 200) {
          return {
            ...responseData,
            status: response.code,
            data: response.data,
            message: response.message,
            count: response.count,
          };
        } else {
          return {
            ...responseData,
            message: response.message,
          };
        }
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      console.log(err, "err");
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};
const createUser = async (data) => {
  const token = await getAuthHeader("single");
  const formData = new FormData();

  formData.append("file", data.file);
  formData.append("email", data.email);
  formData.append("contact", data.contact);
  formData.append("address", data.address);
  formData.append("businessCategory", data.businessCategory);
  formData.append("numberOfProducts", data.numberOfProducts);
  formData.append("monthlyOrders", data.monthlyOrders);
  formData.append("orderConfigurationType", data.orderConfiguration);
  formData.append("workspaceName", data.workspaceName);
  formData.append("type", data.type);
  formData.append("firstName", data.firstName);
  formData.append("lastName", data.lastName);

  return instance
    .post(`/superAdmin/user`, formData, token)
    .then((response) => {
      response = response.data;
      if (response.code === 200 || response.code === 201) {
        return {
          message: response.message,
          status: response.code,
          data: response.data,
        };
      } else {
        return {
          status: response.code,
          message: response.message,
        };
      }
    })
    .catch((err) => {
      return {
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

const attachAttachmentsToProducts = async (values) => {
  const token = await getAuthHeader();

  const dataFile = new FormData();
  dataFile.append("zipFile", values?.zipFile[0] || "");
  dataFile.append("workspaceId", values?.workspaceId || "");
  dataFile.append("format", values?.format || "");
  return instance
    .post(`/products/zipFileAttachments`, dataFile, token)
    .then((response) => {
      response = response.data;
      if (response.code === 200 || response.code === 201) {
        return {
          message: response.message,
          status: response.code,
          data: response.data,
        };
      } else {
        return {
          status: response.code,
          message: response.message,
        };
      }
    })
    .catch((err) => {
      return {
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};
const updateImages = async (workspace_id) => {
  const token = await getAuthHeader();
  return instance
    .post(`/superAdmin/updateImages`, { workspace_id: workspace_id }, token)
    .then((response) => {
      return {
        message: response.data.message,
        status: response.data.code,
      };
    })
    .catch((err) => {
      return {
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

const resendInvitation = async (id) => {
  const token = await getAuthHeader();
  return instance
    .post(`/superAdmin/resendInvitation/${id}`, {}, token)
    .then((response) => {
      return {
        message: response.data.message,
        status: response.data.code,
      };
    })
    .catch((err) => {
      return {
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

const suspendUser = async (id) => {
  const token = await getAuthHeader();
  return instance
    .post(`/superAdmin/suspend/${id}`, {}, token)
    .then((response) => {
      return {
        message: response.data.message,
        status: response.data.code,
      };
    })
    .catch((err) => {
      return {
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

const GetDescriptionTemplates = async (page = 1, limit = 100000) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const token = await getAuthHeader();

  return instance
    .get(`/superAdmin/descriptionTemplates?page=${page}&limit=${limit}`, token)
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        response = response.data;
        if (response.code === 200) {
          return {
            status: 200,
            data: response.data,
            count: response.count,
          };
        } else {
          return {
            ...responseData,
            message: response.message,
          };
        }
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

const FetchDescriptionTemplatesByQuery = async (
  page = 1,
  limit = 100000,
  query = ""
) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };

  const token = await getAuthHeader();
  return instance
    .get(
      `/superAdmin/descriptionTemplates/search?page=${page}&limit=${limit}&query=${query}`,
      token
    )
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        response = response.data;
        if (response.code === 200) {
          return {
            ...responseData,
            status: 200,
            data: response.data,
            count: response.count,
          };
        } else {
          return {
            ...responseData,
            message: response.message,
          };
        }
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

const CreateDescriptionTemplate = async (formData) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };

  const token = await getAuthHeader();
  return instance
    .post(`/superAdmin/descriptionTemplates`, formData, token)
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        response = response.data;
        if (response.code === 200 || response.code === 201) {
          return {
            ...responseData,
            status: 200,
            data: response.data,
            message: response.message,
          };
        } else {
          return {
            ...responseData,
            message: response.message,
          };
        }
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

const UpdateDescriptionTemplate = async (formData) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };

  const { id } = formData;

  const token = await getAuthHeader();

  return instance
    .put(`/superAdmin/descriptionTemplates/${id}`, formData, token)
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        response = response.data;
        if (response.code === 200 || response.code === 201) {
          return {
            ...responseData,
            status: 200,
            data: response.data,
            message: response.message,
          };
        } else {
          return {
            ...responseData,
            message: response.message,
          };
        }
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

const DeleteDescriptionTemplate = async (id) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };

  const token = await getAuthHeader();
  return instance
    .delete(`/superAdmin/descriptionTemplates/${id}`, token)
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        response = response.data;
        if (response.code === 200 || response.code === 201) {
          return {
            ...responseData,
            status: 200,
            data: response.data,
            message: response.message,
          };
        } else {
          return {
            ...responseData,
            message: response.message,
          };
        }
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

const FilterDescriptionTemplatesByType = async (
  values,
  limit = 100000,
  page = 1,
  chatgpt
) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };

  let query = values && values.type ? values.type.id : "";

  const token = await getAuthHeader();
  return instance
    .get(
      `/superAdmin/descriptionTemplates/filter?workspaceId=page=${page}&limit=${limit}&query=${query}&chatgpt=${chatgpt}`,
      token
    )
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        response = response.data;
        if (response.code === 200) {
          return {
            ...responseData,
            status: 200,
            data: response.data,
            count: response.count,
          };
        } else {
          return {
            ...responseData,
            message: response.message,
          };
        }
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

const GetEmailDescriptions = async (page = 1, limit = 100000) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const token = await getAuthHeader();
  return instance
    .get(
      `superAdmin/descriptionTemplates/emails?page=${page}&limit=${limit}`,
      token
    )
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        response = response.data;
        if (response.code === 200) {
          return {
            ...responseData,
            status: 200,
            data: response.data,
            count: response.count,
          };
        } else {
          return {
            ...responseData,
            message: response.message,
          };
        }
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

const FetchEmailDescriptionsByQuery = async (
  page = 1,
  limit = 100000,
  query = ""
) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };

  const token = await getAuthHeader();
  return instance
    .get(
      `superAdmin/descriptionTemplates/emailsSearch?page=${page}&limit=${limit}&query=${query}`,
      token
    )
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        response = response.data;
        if (response.code === 200) {
          return {
            ...responseData,
            status: 200,
            data: response.data,
            count: response.count,
          };
        } else {
          return {
            ...responseData,
            message: response.message,
          };
        }
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

const CreateEmailDescription = async (formData) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };

  const token = await getAuthHeader();
  return instance
    .post(`/superAdmin/descriptionTemplates`, formData, token)
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        response = response.data;
        if (response.code === 200 || response.code === 201) {
          return {
            ...responseData,
            status: 200,
            data: response.data,
            message: response.message,
          };
        } else {
          return {
            ...responseData,
            message: response.message,
          };
        }
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

const UpdateEmailDescription = async (formData) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };

  const { id } = formData;

  const token = await getAuthHeader();
  return instance
    .put(`/superAdmin/descriptionTemplates/${id}`, formData, token)
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        response = response.data;
        if (response.code === 200 || response.code === 201) {
          return {
            ...responseData,
            status: 200,
            data: response.data,
            message: response.message,
          };
        } else {
          return {
            ...responseData,
            message: response.message,
          };
        }
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

export const DeleteEmailDescription = async (id) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };

  const token = await getAuthHeader();
  return instance
    .delete(`/descTemplates/${id}`, token)
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        response = response.data;
        if (response.code === 200 || response.code === 201) {
          return {
            ...responseData,
            status: 200,
            data: response.data,
            message: response.message,
          };
        } else {
          return {
            ...responseData,
            message: response.message,
          };
        }
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};
const FetchTemplateFramesByCatalogueId = async (
  catalogueId = "",
  subCatalogueId = ""
) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const token = await getAuthHeader();
  return instance
    .get(
      `/superAdmin/mongodb/templateFrames/superAdmin/frames?catalogueId=${catalogueId}&subCatalogueId=${subCatalogueId}`,
      token
    )
    .then((response) => {
      if (response.status === 200) {
        response = response.data;
        if (response.code === 200) {
          return {
            ...responseData,
            status: 200,
            data: response.data,
          };
        } else {
          return {
            ...responseData,
            message: response.message,
          };
        }
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

const changeWebhookStatus = async (id = "", status = "") => {
  const responseData = {
    loading: false,
    status: 400,
    message: "Something went wrong, Please try again.",
  };
  const token = await getAuthHeader();
  console.log("INSTANCE==>", instance, id, status);
  return instance
    .put(`/superAdmin/updateWebhookStatus/${id}`, { status }, token)
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        response = response.data;
        if (response.code === 200 || response.code === 201) {
          return {
            ...responseData,
            status: 200,
            data: response.data,
            message: response.message,
          };
        } else {
          return {
            ...responseData,
            message: response.message,
          };
        }
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

const GetWorkspaceWebsite = async (workspace) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const token = await getAuthHeader();
  const workspaceId = workspace?.id;

  return instance
    .get(`/superAdmin/list-website-webhook?workspaceId=${workspaceId}`, token)
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        response = response.data;
        return {
          data: response.data,
          status: response.code,
          website: response?.website,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

const SubscribeWebsiteWebhook = async (workspace, topic) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const token = await getAuthHeader();
  const workspaceId = workspace?.id;

  return instance
    .post(
      `/superAdmin/manage-website-webhook?workspaceId=${workspaceId}&action=subscribe&webhook=${topic}`,
      {},
      token
    )
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        response = response.data;
        return {
          data: response.data,
          status: response.code,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

const UnsubscribeWebsiteWebhook = async (workspace, webhookId) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const token = await getAuthHeader();
  const workspaceId = workspace?.id;

  return instance
    .post(
      `/superAdmin/manage-website-webhook?workspaceId=${workspaceId}&action=unsubscribe&webhook=${webhookId}`,
      {},
      token
    )
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        response = response.data;
        return {
          data: response.data,
          status: response.code,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

const createUserWorkSpace = async (userId, data) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const formData = new FormData();
  formData.append("userId", userId);
  formData.append("name", data.name);
  formData.append("file", data.file);
  formData.append("email", data.email);
  formData.append("contact", data.contact);
  formData.append("address", data.address);
  formData.append("businessCategory", data.businessCategory);
  formData.append("numberOfProducts", data.numberOfProducts);
  formData.append("monthlyOrders", data.monthlyOrders);
  formData.append("orderConfigurationType", data.orderConfiguration);

  const token = await getAuthHeader("single");
  return instance
    .post(`/superAdmin/create-user-workspace`, formData, token)
    .then((response) => {
      if (response.status === 200) {
        response = response.data;
        if (response.code === 200) {
          const data = response.data;
          return {
            ...responseData,
            status: 200,
            message: response.message,
            data,
          };
        }
        return {
          ...responseData,
          message: response.message,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

export {
  getSuperUserData,
  createUser,
  updateImages,
  resendInvitation,
  suspendUser,
  GetDescriptionTemplates,
  FetchDescriptionTemplatesByQuery,
  UpdateDescriptionTemplate,
  CreateDescriptionTemplate,
  DeleteDescriptionTemplate,
  FilterDescriptionTemplatesByType,
  GetEmailDescriptions,
  FetchEmailDescriptionsByQuery,
  UpdateEmailDescription,
  CreateEmailDescription,
  GetWorkspacesDetail,
  GetWorkspacesDetailByQuery,
  getUserWorkspaces,
  FetchTemplateFramesByCatalogueId,
  updateDummyOrders,
  attachAttachmentsToProducts,
  importCSVOrders,
  changeWebhookStatus,
  GetWorkspaceWebsite,
  SubscribeWebsiteWebhook,
  UnsubscribeWebsiteWebhook,
  GetWorkspaceList,
  GetWebsites,
  createUserWorkSpace,
};
