export const DASHBOARD = "Dashboard";
export const TRANSACTIONS = "Transactions";
export const SETTINGS = "Settings";
export const LOCATIONS = "Locations";
export const LOCATION = "Location";
export const AVAILABLE = "Available:";
export const NEW_STOCK = "New Stock:";
export const CURRENCY = "Rs";
export const MY_ACCOUNT = "My Account";
export const DEFAULT = "default";
export const AUDIO = "audio";
export const VIDEO = "video";
export const CENTER = "center";
export const MOBILE = "mobile";
export const IS_POSITIVE = "Is Positive?";
export const VALID_NAME_ALPHABET = /^[a-zA-Z -_]+$/i;
export const VALID_NAME = /^[a-zA-Z0-9 _-]+$/i;
export const VALID_NUMBER = /^[0-9]+$/i;
export const _RESIZE = "resize";
export const _RIGHT = "right";
export const _LEFT = "left";
export const _TOP = "top";
export const _ASC = "asc";
export const _DESC = "desc";
export const _ROLE = "role";
export const _CATEGORY = "category";
export const _PRODUCT = "product";
export const _BULK = "bulk";
export const _NO_CACHE = "no-cache";
export const _CREATED_AT = "createdAt";
export const _UPDATED_AT = "updatedAt";
export const _SMALL = "small";
export const _MEDIUM = "medium";
export const _LOW = "low";
export const _HIGH = "high";
export const _TEXT = "text";
export const _DATE = "date";
export const _NAME = "name";
export const _EMAIL = "email";
export const _PASSWORD = "password";
export const _CONFIRMPASSWORD = "confirmPassword";
export const _CURRENTPASSWORD = "currentPassword";
export const _DATE_TIME_LOCAL = "datetime-local";
export const _NUMBER = "number";
export const _SUBMIT = "submit";
export const _BUTTON = "button";
export const _CONTAINED = "contained";
export const _OUTLINE = "outlined";
export const _STANDARD = "standard";
export const _PRIMARY = "primary";
export const _SECONDARY = "secondary";
export const _PERMANENT = "permanent";
export const TOTAL_QUANTITY = "total_quantity";
export const _TRUE = "true";
export const _ALL = "all";
export const _EDIT = "edit";
export const _INACTIVE = "Inactive";
export const _ACTIVE = "Active";
export const _CHECKED_A = "checkedA";
export const _SNOW = "snow";
export const _WAVE = "wave";
export const _CIRCLE = "circle";
export const _TIMELINE = "timeline";
export const _ALTERNATE = "alternate";
export const DOLLER_SIGN = "$";
export const QUESTION_MARK = "?";
export const SET_PASSWORD = "Set Password";
export const RESET_PASSWORD = "Reset Password";
export const _CONFIRM_TOKEN = "confirmToken";
export const _CONFIRMATION_TOKEN = "confirmationToken";
export const OFFSET = 50;
// export const ROWS_PER_PAGE = [25, 50, 75, 100];
export const ROWS_PER_PAGE = [50, 100, 150, 200];
export const offset = 50;
export const XL = "xl";
export const LG = "lg";
export const MD = "md";
export const SM = "sm";
export const filterData = {};
export const TIME = "HH:mm";
export const TIME_ = "hh:mm A";
export const DATE_TIME = "YYYY-MM-DD HH:mm:ss";
export const POST_DATE_TIME = "YYYY-MM-DDTHH:mm";
export const DATE_PK = "DD-MM-YYYY";
export const DATE_TIME_PK = "DD-MM-YYYY HH:mm:ss";
export const DATE = "YYYY-MM-DD";
export const CALENDAR_DATE = "MMM YY";
export const RS = "Rs";
export const _OFF = "off";
export const _END = "end";
export const _YES = "yes";
export const _NO = "no";
export const NA = "N/A";
export const _CONTACT_NO = "contactNo";
export const _PHONE_NO = "phone";
export const _LOAD_SHEET = "loadSheet";
export const _ORRDERS = "orders";
export const _ORDERS = "orders";
export const _WAREHOUSE = "warehouse";
export const _QUEUE = "queue";
export const _WAREHOUSES = "warehouses";
export const _SOCIAL_GROUPS = "socialGroups";

export const _TIKTOK = "Tiktok";
export const _POS = "POS";
export const _MODULEUPDATE = "moduleUpdate";
export const _GMAILTHREAD = "gmailThread";
export const _ORDERDETAIL = "orderDetail";
export const _PRODUCTDETAIL = "productDetail";
export const _RETURN_ORDERS = "returnOrders";
export const _Categories = "_categories";
export const _ATTRIBUTES = "attributes";
export const _DESIGNJOBS = "designJobs";
export const _CATALOUGES = "catalogues";
export const _Emails = "_emails";
export const _ACTIVITIES = "_activities";

export const _LABELS = "labels";
export const _PERMISSIONS = "permissions";
export const _MailGroups = "_mailGroups";
export const _PRODUCTS = "products";
export const _PRODUCT_NAME = "productName";
export const _CONTINUE_SELLING = "continueSelling";
export const _CATEGORIES = "categories";
export const _PRODUCT_CODE = "productCode";
export const _CODE = "code";
export const _TAGS = "tags";
export const _PREFERENCE = "preference";
export const _DESCRIPTION = "description";
export const _DESIGN_TEMPLATES = "designTemplates";
export const _EMAIL_DESCRIPTIONS = "emailDescriptions";
export const _STATUSES = "statuses";
export const _SHIPPERS = "shippers";
export const _SHIPPERS_SETTINGS = "shippersSettings";
export const _PAYMENTS = "payments";
export const _IMAGES = "images";
export const _ATTACHMENTS = "attachments";
export const _IMAGE = "ProfilePicture";
export const _PROFILE = "Profile Picture";
export const _AUDIO = "audioFile";
export const _VIDEO = "videoFile";
export const _MOBILE_IMAGES = "mobileImages";
export const _PRODUCT_VARIANT = "variant";
export const _PRODUCT_VARIANT_OBJ = "variantObj";
export const _SKU = "sku";
export const _PRICE = "price";
export const _COST_PRICE = "cost_price";
export const _SALE_PRICE = "sale_price";
export const _PRODUCT_TYPE = "productType";
export const _VENDOR = "vendor";
export const _INDEX = "index";
export const _SOCIAL_PROFILE = "socialProfile";
export const _ID = "id";
export const _WAREHOUSE_ID = "warehouse_id";
export const _QUANTITY = "quantity";
export const _BOX_NO = "box_no";
export const _SHELF_NO = "shelf_no";
export const _RACK_NO = "rack_no";
export const _SUPERADMIN = "super_admin";
export const PRODUCT_VARIANT = "product_variants";
export const VARIANT_QUANTITY = "variant_quantity";
export const DateFormat = "YYYY-MM-DD HH:mm:ss";
export const DateDayFormat = "MMM D YYYY, h:mm a";
export const WOOCOMMERCE = "WOOCOMMERCE";
export const WHATSAPP = "WHATSAPP";
export const SHOPIFY = "SHOPIFY";
export const WORKSPACES = "Workspaces";
export const LOCAL = "LOCAL";
export const PHOTO = "photo";
export const REEL = "reel";
export const LINK = "link";
export const _CONVERSATION_ORDER = "conversation_order";
export const _SIGNATURE = "Signature";

export const _SAMPLE_WORKSPACE_ID = 1;
export const _SHUTTLE_PAGE_ID = 1;
export const menuType = "left";

export const SAMPLE_WORKSPACE_DISABLE_ACTIONS = [
  "Edit",
  "Delete",
  "Stock Update",
];
export const SPINNER_SIZE = {
  EXTRAXSMALL: 8,
  EXTRASMALL: 12,
  TINY: 16,
  SMALL: 20,
  LARGE: 32,
  LARGER: 48,
};
export const Routes = {
  LOGIN: "/login",
  REGISTER: "/register",
  FORGET_PASSWORD: "/forget-password",
  RESET_PASSWORD: "/reset-password",
  CONFIRMATION: "/confirmation",
  PRIVACY_POLICY: "/privacy-policy",
  ECOMMERCE_HOMEPAGE: "/:name/:id/homepage",
  ECOMMERCE_PRODUCT_SHOW: "/:name/:id/product/:productId",
  ECOMMERCE_CART: "/:name/:id/cart",
  ECOMMERCE_STORE: "/:name/:id/homepage",
  ECOMMERCE_STORE_CHECKOUT: "/:name/:id/checkout",
  ECOMMERCE_STORE_ORDER_CONFIRMATION: "/:name/:id/order-confirmation",
  WORKSPACES: "/workspaces",
  WORKSPACES_INTEGRATION: "/workspaces-integration",
  GOOGLE_CREDIETIALS: "/workspaces/google/callBack",
  TIKTOK_CREDIETIALS: "/workspaces/tiktok/callBack",
  LINKEDIN_CREDIETIALS: "/workspaces/linkedin/callBack",
  DASHBOARD: "/workspaces/:id/dashboard",
  DEMO_DASHBOARD: "/workspaces/:id/dashboard",
  SAMPLE_DASHBOARD: "/workspaces/:id/demo-dashboard",
  PRODUCTS: "/workspaces/:id/products",
  PRODUCT_BY_ID: "/workspaces/:id/products/:id",
  ORDERS: "/workspaces/:id/orders",
  ORDERS_PDF: "/workspaces/:id/ordersPdf",
  LOAD_SHEET: "/workspaces/:id/loadSheet",
  ORDERS_REPORTS: "/workspaces/:id/ordersReports",
  RETURN_ORDERS_REPORTS: "/workspaces/:id/returnOrdersReports",
  AGENTS_REPORT: "/workspaces/:id/agentsReport",
  CUSTOMER_REPORT: "/workspaces/:id/customer-report",
  CUSTOMER_REPORT_PROFILE: "/workspaces/:id/customer-report-profile/:id",
  ORDER_BY_ID: "/workspaces/:id/order/:orderid",
  GMAIL_THREADS_BY_SUBJECT: "/workspaces/:id/order/:id/gmailthreads",
  GMAIL_THREADS_REPLY: "/workspaces/:id/order/gmailThreadsReply/:threadId",
  CUSTOMER_ORDERS: "/workspaces/:id/orders/:customerid",
  TAGS: "/workspaces/:id/tags",
  SOCIALGROUPS: "/workspaces/:id/socialGroups",
  CATEGORIES: "/workspaces/:id/categories",
  ATTRIBUTES: "/workspaces/:id/attributes",
  IMPORTS: "/workspaces/:id/imports",
  EXPORTS: "/workspaces/:id/exports",
  WAREHOUSES: "/workspaces/:id/locations",
  LABELS: "/workspaces/:id/labels",
  DESIGN: "/workspaces/:id/design",
  TEMPLATES: "/workspaces/:id/templates",
  TEMPLATESDESIGNS: "/workspaces/:id/designTemplates",
  DESCRIPTION_TEMPLATES: "/workspaces/:id/description-templates",
  DESIGN_JOBS: "/workspaces/:id/design-jobs",
  DESIGN_JOBS_DETAILS: "/workspaces/:id/design-jobs/:id",
  SOCIAL_PROFILES: "/workspaces/:id/socialProfiles",
  TIK_TOK: "/workspaces/:id/tiktok",
  SOCIAL_PROFILES_BY_ID: "/social-profiles/:id",
  ACTIVITIES: "/workspaces/:id/activities",
  SIGNATURES: "/workspaces/:id/signature",
  MAIL_GROUPS: "/workspaces/:id/mail-groups",
  EMAILS: "/workspaces/:id/emails",
  CONVERSATIONS: "/workspaces/:id/conversations",
  CONVERSATION: "/workspaces/:id/conversation",
  CONVERSATIONS_OLD: "/workspaces/:id/conversations-old",
  RECONCILIATION: "/workspaces/:id/activities",
  SHIPPERS: "/workspaces/:id/shippers",
  PAYMENTS: "/workspaces/:id/payments",
  // STATUSES: "/workspaces/:id/statuses",
  QUEUE: "/superAdmin/queue",
  STATUSES: "/statuses",
  POS: "/workspaces/:id/pos",
  EMAIL_TEMPLATES: "/workspaces/:id/email-templates",
  PERMISSIONS: "/workspaces/:id/permissions",
  INTEGRATION_SETTINGS: "/workspaces/:id/integration",
  SETTINGS: "/workspaces/:id/settings",
  SHIPPER_SETTINGS: "/workspaces/:id/shipper-settings",
  BUSINESS_RULES_SETTINGS: "/workspaces/:id/business-rules-settings",
  USERS: "/users",
  INVITATION: "/invitation",
  UPDATE_IMAGES: "/update",
  TASK_MANAGER: "/workspaces/:id/task-manager",
  SUPER_ADMIN_DESCRIPTION_TEMPLATES: "/superAdmin/description-templates",
  SUPER_ADMIN_EMAIL_TEMPLATES: "/superAdmin/email-templates",
  SUPER_ADMIN_DESIGN_TEMPLATES: "/superAdmin/design-templates",
  SUPER_ADMIN_UPDATE_DUMMY_ORDERS: "/superAdmin/dummy_orders",

  SUPER_ADMIN_TEMPLATES: "/superAdmin/templates",
  SUPER_ADMIN_TEMPLATES_DESIGNS: "/superAdmin/designTemplates",
  SUPER_ADMIN_ATTACHMENTS: "/superAdmin/attachments",
  USER_WORKSPACES: "/:id/workspaces",
  MEMBERS: "/workspaces/:id/members",
  CRM: "/workspaces/:id/crm",
};
export const SuperUserRoutes = [
  Routes.USERS,
  Routes.STATUSES,
  Routes.UPDATE_IMAGES,
  Routes.QUEUE,
  Routes.SUPER_ADMIN_DESCRIPTION_TEMPLATES,
  Routes.SUPER_ADMIN_EMAIL_TEMPLATES,
  Routes.USER_WORKSPACES,
  Routes.WORKSPACES,
  Routes.WORKSPACES_INTEGRATION,
  Routes.SUPER_ADMIN_DESIGN_TEMPLATES,
  Routes.SUPER_ADMIN_TEMPLATES,
  Routes.SUPER_ADMIN_UPDATE_DUMMY_ORDERS,
  Routes.SUPER_ADMIN_ATTACHMENTS,
  Routes.TEMPLATE,
];
export const fileErrors = {
  "file-too-large": "File is too large",
  "file-invalid-type": "Invalid file type.",
  "square-image-error": "Image aspect ratio must be 1:1",
  other: "File not Uploaded",
};
